@import url("https://fonts.googleapis.com/css2?family=DM+Sans&family=Inter&family=Lato:wght@300&family=Poppins:wght@300;700&family=Roboto&family=Urbanist:wght@700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  /* White Color*/
  /* --clr-primary: #ffffff; */
  --clr-secondary: #504298;
  --clr-main-heading: #3a3939;
  --clr-small-main-heading: #213d39;
  --clr-gray: #2d3748;
  --clr-white: #ffffff;
  --clr-black: #000000;
  --clr-red: #ef0622;
  --clr-ash: #e2dfee;
  --clr-card-text: #323232;
  --clr-input-bg: #f5f5ff;
  --clr-input-text: #9f9f9f;
  --clr-dropdown-head: "#686666";
}
